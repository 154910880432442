












































































import Vue from "vue";
import HelloUser from "@/components/shared/HelloUser.vue";
import SearchAnything from "@/components/recruiter/dashboard/SearchAnything.vue";
import DashboardStatistics from "@/components/recruiter/dashboard/DashboardStatistics.vue";
import EmiratisationStatistics from "@/components/recruiter/dashboard/EmiratisationStatistics.vue";
import SourcesInformationStatistics from "@/components/recruiter/dashboard/SourcesInformationStatistics.vue";
import DashboardStatisticsNumber from "@/components/recruiter/dashboard/DashboardStatisticsNumber.vue";
import moment from "moment/moment";
import { MembersJobsStatistics } from "@/store/modules/recruiter/interfaces";
import { clean_string } from "@/utils/global";
import { mapActions } from "vuex";
import { MEMBERS_JOBS_STATISTICS } from "@/store/modules/recruiter/constants";
import { RecruiterDashboard } from "@/interfaces/recruiter/dashboard/recruiter_dashboard";
// import { socket } from "@/services/socket.service";

export default Vue.extend({
  name: "RecruiterDashboard",
  components: {
    EmiratisationStatistics,
    DashboardStatistics,
    SearchAnything,
    HelloUser,
    SourcesInformationStatistics,
    DashboardStatisticsNumber
  },
  data(): RecruiterDashboard {
    return {
      stat_error: false,
      stat_loading: true,
      chart_data_members: [],
      chart_data_jobs: [],
      emiratisation_stats: {
        local_count: 1,
        foreign_count: 2,
        total_users: 3
      }
    };
  },
  async mounted() {
    const filter = sessionStorage.getItem("filter");
    if (filter && clean_string(filter) === "monthly") {
      const end_date = moment().add(1, "day");
      const start_date = moment().subtract(30, "days");
      const payload: MembersJobsStatistics = {
        filter: clean_string("weekly"),
        start_date: start_date.format("YYYY-MM-DD"),
        end_date: end_date.format("YYYY-MM-DD")
      };
      await this.fetch_stats(payload);
    } else {
      const end_date = moment().add(1, "day");
      const start_date = moment().subtract(7, "days");
      const payload: MembersJobsStatistics = {
        filter: clean_string("weekly"),
        start_date: start_date.format("YYYY-MM-DD"),
        end_date: end_date.format("YYYY-MM-DD")
      };
      await this.fetch_stats(payload);
    }
  },
  methods: {
    ...mapActions("recruiter", {
      fetch_member_jobs_statistics: MEMBERS_JOBS_STATISTICS
    }),
    /**
     * Function to fetch stats
     */
    async fetch_stats(payload: MembersJobsStatistics) {
      this.stat_loading = true;
      const data = await this.fetch_member_jobs_statistics(payload);
      if (data) {
        this.chart_data_members = data.members;
        this.chart_data_jobs = data.jobs;
        this.emiratisation_stats = {
          foreign_count: 2,
          local_count: 3,
          total_users: 5
        };
      } else {
        this.stat_error = true;
      }
      this.stat_loading = false;
    },
    set_members(members: number) {
      this.emiratisation_stats.total_users = 5;
    }
    // emitSOcket() {
    //   socket.emit("chat message", "hetlv");
    // }
  }
});
