











































































import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { EmiratisationStatsResponse } from "@/interfaces/responses/statistics";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "SourcesInformationStatistics",
  components: {
    DataNotFound,
    // DataNotFound
    "vue-apex-charts": VueApexCharts
  },
  data() {
    return {
      options: {}
    };
  },
  props: {
    stats: {
      type: Object as PropType<EmiratisationStatsResponse>
    },
    stat_error: {
      type: Boolean
    }
  },
  mounted() {
    this.initial_bar_options();
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  watch: {
    get_site_direction() {
      this.initial_bar_options();
    },
    "$vuetify.breakpoint.xsOnly": function () {
      this.initial_bar_options();
    },
    "$vuetify.breakpoint.smAndUp": function () {
      this.initial_bar_options();
    }
  },
  methods: {
    get_title() {
      this.$t("shared.failed-data");
    },
    initial_bar_options() {
      this.options = {
        colors: ["#fd9b8d", "#61ccff", "#ffcb56", "#a366f5", "#58ee94"],
        chart: {
          type: "donut"
        },
        series: [10, 20, 30, 40],
        labels: [
          this.$t("recruiter.hr-internal.agency"),
          this.$t("recruiter.hr-internal.referal"),
          this.$t("recruiter.hr-internal.linkedin"),
          this.$t("recruiter.hr-internal.dubia-careers"),
          this.$t("recruiter.hr-internal.career-fairs")
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "end",
          // formatter: function (val: any) {
          //   return parseInt(val.toFixed(2)) + "%";
          // },
          style: {
            fontSize: "16px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: ["#fd9b8d", "#61ccff", "#ffcb56", "#a366f5", "#58ee94"]
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              size: "65%"
            }
          }
        }
      };
    }
  }
});
