













import { DashboardNumbersCount } from "@/interfaces/recruiter/dashboard/dashboard_numbers";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "DashboardStatisticsNumber",
  components: {},
  data() {
    return {
      options: [] as DashboardNumbersCount[]
    };
  },
  mounted() {
    this.set_options();
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  watch: {
    get_site_direction() {
      this.set_options();
    }
  },
  methods: {
    set_options() {
      this.options = [
        {
          value: "445",
          text: this.$t("recruiter.hr-internal.total-applicant"),
          color: "danger-light"
        },
        {
          value: "267",
          text: this.$t("recruiter.hr-internal.shortlisted-candidates"),
          color: "success"
        },
        {
          value: "89",
          text: this.$t("recruiter.hr-internal.hired-candidates"),
          color: "warning"
        },
        {
          value: "178",
          text: this.$t("recruiter.hr-internal.rejected-candidates"),
          color: "purple"
        }
        // {
        //   value: "67",
        //   text: this.$t("recruiter.hr-internal.time-to-hire"),
        //   color: "info"
        // }
      ];
    }
  }
});
